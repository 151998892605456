<template>
	<section class="mb-5">
		<div class="row">
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12"><b>Første sygedag</b> <i>Du bedes kun registere sygdom over 14 dags varighed</i></div>
					<div class="col-12">
						<InputDate v-model="sickLeave.sickStartDate" :disabled="disabled" :required="true"> </InputDate>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>Må vi {{ owner }} kontakte medarbejderen?</b>
					</div>
					<div class="col-12">
						<InputCheck :disabled="disabled" v-model="sickLeave.canWeContactThePerson" :required="true"> </InputCheck>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import InputDate from '@/modules/global/atomComponents/InputDate'
import InputCheck from '@/modules/global/atomComponents/InputCheck'
import conf from '../../../../../conf.json'

export default {
	name: 'IndividualCondition',
	components: {
		InputDate,
		InputCheck,
	},
	props: {
		value: Object,
		disabled: Boolean,
	},
	computed: {
		sickLeave: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		owner() {
			return conf.customization.name
		},
	},
	mounted() {},
}
</script>

<style></style>
